<template>
  <div class="row-history">
    <el-container class="row-con">
      <el-header style="visibility:hidden;" class="m-filter">
        <div class="wp">
          <div class="filter">

          </div>
        </div>
      </el-header>

      <div class="total">
        <div class="total_top" :class="total_top">
          <div class="texts">
            <h3>总桩数</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalPileNum}}根</div>
          </div>
        </div>

        <div class="total_top" :class="total_top">
          <div class="texts">
            <h3>总延米</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalDepth|numfilters}}m</div>
          </div>
        </div>
        <div class="total_top" :class="total_top">
          <div class="texts">
            <h3>总浆量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalCumulativePulp|numfilters}}L</div>
          </div>
        </div>
        <div class="total_top" :class="total_top">
          <div class="texts">
            <h3>总灰量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalCumulativeAsh/1000|numfilters}}t</div>
          </div>
        </div>
        <div class="total_top" :class="total_top" v-show="show">
          <div class="texts">
            <h3>平均灰量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.avgAsh|numfilters}}kg/m</div>
          </div>
        </div>
      </div>

      <el-main style="visibility:hidden;" class="m-table-main m-table-alertInfo">
        <!-- <div class="inner" style="width:100%;height:90%">
          <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
        </div> -->
      </el-main>
    </el-container>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['totalNum', 'show'],
  data() {
    return {
      total_top: ''
    }
  },
  beforeUpdate() {
    if (this.show == false) {
      this.total_top = 'totalnum_top'
    } else {
      this.total_top = 'totals_top'
    }
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    }
  }
}
</script>
<style scoped>
.total_top {
  float: left;
  width: 18%;
  color: #fff;
  border-radius: 10px;
  margin-left: 1.875rem;
}
.totals_top {
  width: 18%;
}
.totalnum_top {
  width: 23%;
}
.texts {
  padding: 8px 0 8px 15px;
  background: #1590ee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.total_top .bottom {
  background: #fff;
  color: #333;
  padding: 5px 0 5px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 1.2rem;
  margin-bottom: 5px;
}
</style>
