<template>
  <div class="row-history">
    <!-- m-topNav -->
    <!-- <div class="m-topNav">
      <h2>产量统计</h2>
    </div> -->
    <div class="m-topNav">
      <el-tabs @tab-click="handleClick" v-model="tab">
        <el-tab-pane v-for="item of tabList" :key="item.name" :label="item.label" :name="item.name">
        </el-tab-pane>
      </el-tabs>
    </div>
    <component :is='tab' :deviceList='deviceList' :totalNum='totalNum' :meter='meter' :pile='pile' :show='show'></component>
    <!-- m-topNav end -->
    <!-- row-con -->
    <el-container class="row-con">
      <el-header style="z-index:100" class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button class="btn btn-search" type="primary" @click="query" icon="el-icon-search">查询</el-button>
                <el-button class="btn btn-reset" native-type="reset"><span class="icon icon-reset"></span><span>重置</span></el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format='timestamp' type="date" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format='timestamp' type="date" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="device" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <div class="total" style="visibility:hidden;">
        <div class="total_top">
          <div class="texts">
            <h3>总桩数</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalPileNum}}根</div>
          </div>
        </div>

        <div class="total_top">
          <div class="texts">
            <h3>总延米</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalDepth|numfilters}}m</div>
          </div>
        </div>
        <div class="total_top">
          <div class="texts">
            <h3>总浆量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalCumulativePulp|numfilters}}L</div>
          </div>
        </div>
        <div class="total_top">
          <div class="texts">
            <h3>总灰量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.totalCumulativeAsh|numfilters}}t</div>
          </div>
        </div>
        <div class="total_top">
          <div class="texts">
            <h3>平均灰量</h3>
          </div>
          <div class="bottom">
            <div class="value">{{totalNum.avgAsh|numfilters}}kg/m</div>
          </div>
        </div>
      </div>

      <!-- <el-main class="m-table-main m-table-alertInfo"> -->
      <div class="inner" style="width:100%;height:90%">
        <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
      </div>
      <!-- </el-main> -->
    </el-container>
  </div>
</template>

<script>
import { queryDevices, analysis, historyAnalysis, queryGroups } from '@/api/api'
import moment from 'moment'
import * as echarts from "echarts"
import CFG from '../statistical/CFG.vue'
import GH from '../statistical/GH.vue'
import GYXPZ from '../statistical/GYXPZ.vue'
import JBZ from '../statistical/JBZ.vue'
import GJ from '../statistical/GJ.vue'
import DK from '../statistical/DK.vue'
import CP from '../statistical/CP.vue'
import FPZ from '../statistical/FPZ.vue'
import GZ from '../statistical/GZ.vue'
import CGSZ from '../statistical/CGSZ.vue'
import GFZ from '../statistical/GFZ.vue'
import XWZ from '../statistical/XWZ.vue'
import HTZJ from '../statistical/HTZJ.vue'
import ZKFPZ from '../statistical/ZKFPZ.vue'
import DP from '../statistical/DP.vue'
import LG from '../statistical/LG.vue'
import SP from '../statistical/SP.vue'
import A_RD from '../statistical/A_RD.vue'
export default {
  // name: 'AlertInfo',
  components: {
    CFG, GH, GYXPZ, JBZ, GJ, DK, CP, FPZ, GZ, CGSZ, GFZ, XWZ, HTZJ, ZKFPZ, DP, LG, SP, A_RD
  },
  data() {
    return {
      show: true,
      tabList: [],
      tab: 'CFG',
      data: [],
      meter: [],
      pile: [],
      totalNum: [],
      startDate: '',
      endDate: '',
      device: '',
      deviceKey: '',
      deviceList: [],
      tableData: [
        {
          _id: "613174e6fe9f473d15fd261f",
          describe: 'SY4-4-2灰量报警,设计灰量55.0Kg/m,实际灰量27.830647Kg/m',
          // 预警时间
          createAt: 1630631778,
          // 桩号
          itemid: "K785+241.7-K785+528.7+0_17-12",
        },
      ],
      maxmeternum: 0,
    }
  },
  mounted() {
    queryGroups().then(res => {
      this.tabList = res;
      this.tab = this.tabList[0].name;
      this.handleClick()
    })

  },

  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    }
  },
  watch: {
    tab(val, oldval) {
      if (val) {
        this.meter = []
        this.pile = []
        this.startDate = ''
        this.endDate = ''
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose() //监听弹出框在关闭时将meter和pile里边的数据都清空
      }
    }
  },
  methods: {
    // 点击顶部标签切换
    handleClick() {
      let uint = ''
      if (this.tab == 'A_RD') {
        uint = '平米'
      } else {
        uint = '延米'
      }
      queryDevices(999, 0, this.tab).then(res => {
        // 获取设备列表参数
        this.deviceList = res.result.records
      })
      historyAnalysis(this.deviceKey, this.tab, '', '').then(res => {
        this.totalNum = res
        if (this.totalNum.avgAsh == undefined) {
          this.show = false
        } else {
          this.show = true
        }
      })
      analysis(99999, '', '', '', this.tab).then(res => {
        var maxmeter = []
        for (var i = 0; i < res.result.length; i++) {
          var timestamp = moment(res.result[i].date).valueOf();
          maxmeter.push(res.result[i].depth)
          this.maxmeternum = (Math.max(...maxmeter) * 1.1).toFixed(0)
          let dataMeter = []
          dataMeter.push(timestamp, res.result[i].depth)
          this.meter.push(dataMeter)

          let dataPile = []
          dataPile.push(timestamp, res.result[i].pileAmount)
          this.pile.push(dataPile)
        }
        this.meter = [...this.meter].reverse()
        this.pile = [...this.pile].reverse()
        this.initEchart01(uint);
        let self = this;
        window.onresize = function () {
          self.scale = Math.min(document.documentElement.clientWidth / 1920, 2);
          self.myChart01.resize();
        };
      })

    },
    // // query是点击查询的方法
    query() {
      let uint = ''
      if (this.tab == 'A_RD') {
        uint = '平米'
      } else {
        uint = '延米'
      }
      if (this.endDate != '') {
        this.endDate = this.endDate + 86399000
      } else {
        this.endDate = ''
      }
      historyAnalysis(this.device, this.tab, this.startDate, this.endDate).then(res => {
        this.totalNum = res
      })
      this.meter = []
      this.pile = []
      this.maxmeternum = 0
      if (this.myChart01 === undefined || this.myChart01._disposed) return
      this.myChart01.dispose() //监听弹出框在关闭时将flow和deep里边的数据都清空
      let beginTime = ''
      beginTime = this.startDate
      let endTime = ''
      endTime = this.endDate
      if (beginTime != '') {
        beginTime = moment(beginTime).format('YYYY-MM-DD')
      }
      if (endTime != '') {
        endTime = moment(endTime).format('YYYY-MM-DD')
      }
      analysis(99999, this.device, beginTime, endTime, this.tab).then(res => {
        var maxmeter = []
        for (var i = 0; i < res.result.length; i++) {
          var timestamp = new Date(res.result[i].date).getTime()
          maxmeter.push(res.result[i].depth)
          this.maxmeternum = (Math.max(...maxmeter) * 1.1).toFixed(0)
          let dataMeter = []
          dataMeter.push(timestamp, res.result[i].depth)
          this.meter.push(dataMeter)

          let dataPile = []
          dataPile.push(timestamp, res.result[i].pileAmount)
          this.pile.push(dataPile)
        }
        this.meter = [...this.meter].reverse()
        this.pile = [...this.pile].reverse()
        this.initEchart01(uint);
        let self = this;
        window.onresize = function () {
          self.scale = Math.min(document.documentElement.clientWidth / 1920, 2);
          self.myChart01.resize();
        };
      })
    },

    initEchart01(uint) {
      let option = {
        tooltip: {
          trigger: 'axis',
          // position: function (pt) {
          //   return [pt[0], '10%'];
          // },
        },
        legend: {
          top: "3.5%",
          data: [uint, "成桩"],
        },
        title: {
          left: 'center',
          text: '产量统计'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          axisPointer: {
            label: {
              formatter: function (params) {
                var date = new Date(params.value)
                var day = date.toLocaleDateString();
                return day;
              }
            }
          }
        },
        yAxis: [{
          name: uint,
          type: 'value',
          // max: this.maxmeternum,

        }, {
          name: '成桩',
          type: 'value',
          splitLine: {
            show: false
          }
        }],
        dataZoom: [
          {
            type: 'inside',
            start: 80,
            end: 100,
          },
          {
            start: 80,
            end: 100,
          }
        ],
        series: [
          {
            name: uint,
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: this.meter
          },
          {
            name: '成桩',
            type: 'bar',
            yAxisIndex: 1,
            smooth: true,
            symbol: 'none',
            data: this.pile
          }
        ]
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
    }
  }
}
</script>

<style>
.total_top {
  float: left;
  width: 18%;
  color: #fff;
  border-radius: 10px;
  margin-left: 1.875rem;
}
.texts {
  padding: 8px 0 8px 15px;
  background: #1590ee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.total_top .bottom {
  background: #fff;
  color: #333;
  padding: 5px 0 5px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 1.2rem;
  margin-bottom: 5px;
}
</style>